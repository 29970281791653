/* line 6, source/sass/image-picker.scss */
ul.thumbnails.image_picker_selector {
  overflow: auto;
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  padding: 0px;
  margin: 0px; }
  /* line 15, source/sass/image-picker.scss */
  ul.thumbnails.image_picker_selector ul {
    overflow: auto;
    list-style-image: none;
    list-style-position: outside;
    list-style-type: none;
    padding: 0px;
    margin: 0px; }
  /* line 25, source/sass/image-picker.scss */
  ul.thumbnails.image_picker_selector li.group_title {
    float: none; }
  /* line 30, source/sass/image-picker.scss */
  ul.thumbnails.image_picker_selector li {
    margin: 0px 12px 12px 0px;
    float: left; }
    /* line 35, source/sass/image-picker.scss */
    ul.thumbnails.image_picker_selector li .thumbnail {
      padding: 6px;
      border: 1px solid #DDD;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none; }
      /* line 42, source/sass/image-picker.scss */
      ul.thumbnails.image_picker_selector li .thumbnail img {
        -webkit-user-drag: none; }
    /* line 48, source/sass/image-picker.scss */
    ul.thumbnails.image_picker_selector li .thumbnail.selected {
      background: #08C; }
